/*============================================================================*\
  Slider products
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .slider-products__btn-arrow {
    @apply text-black;
    transition: color 0.3s, opacity 0.3s;

    &:hover,
    &:focus {
      @apply text-orange-dark;
      outline: none;
    }

    &:disabled {
      cursor: default;
      opacity: 0.3;

      &:hover,
      &:focus {
        @apply text-black;
        outline: none;
      }
    }
  }

  .slider-products__item {
    @screen s {
      .product-miniature {
        max-width: 72.489083%;
        margin-right: auto;
        margin-left: auto;
        transition: max-width 0.5s; /* stylelint-disable-line */
      }

      &.is-selected {
        .product-miniature {
          max-width: 100%;
        }
      }
    }
  }

  .slider-products__list {
    outline: none;

    &:not(.is-flickity-ready) {
      .slider-products__item {
        .product-miniature {
          max-width: 100%;
        }

        &:not(:first-child) {
          z-index: -1;
          position: absolute;
          opacity: 0;
        }
      }
    }

    &.is-flickity-ready {
      .flickity-viewport {
        overflow: visible;
      }
    }
  }
}
