/*============================================================================*\
  App styles
\*============================================================================*/

/* Config
\*==========================================================================*/

@import './config';

/* Prestashop theme
\*==========================================================================*/

@import './theme/theme';

/* Utilities
\*==========================================================================*/

@import './utilities/mixins';

/* Base
\*==========================================================================*/

@tailwind base;
@import './base/global';
@import './base/header-desktop';
@import './base/header-mobile';
@import './base/menu-desktop';
@import './base/menu-mobile';
@import './base/footer';

/* Vendors
\*==========================================================================*/

@import './vendors/flickity';
@import './vendors/selectric';

/* Elements
\*==========================================================================*/

@import './elements/badge';
@import './elements/breakpoints';
@import './elements/btn';
@import './elements/cursor';
@import './elements/header-height';
@import './elements/hr';
@import './elements/icon';
@import './elements/link';
@import './elements/alert';
@import './elements/price-style';
@import './elements/window';
@import './elements/wysiwyg';
@import './elements/field-label';
@import './elements/field-text';
@import './elements/field-textarea';
@import './elements/field-status';
@import './elements/field-select';
@import './elements/field-checkbox';
@import './elements/field-radio';
@import './elements/field-number';
@import './elements/field-file';

/* Components
\*==========================================================================*/

@tailwind components;
@import './components/block-categories';
@import './components/block-collaborations';
@import './components/headline';
@import './components/hero';
@import './components/hero-slider';
@import './components/modal';
@import './components/slider-products';
@import './components/sewing-line';
@import './components/brand';
@import './components/group-accessories';

/* Utilities
\*==========================================================================*/

@tailwind utilities;
