/*============================================================================*\
  Window
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .window {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;

    &:not(.window--no-filter) {
      filter: grayscale(1);
      mix-blend-mode: luminosity;
      opacity: 0.6;
    }
  }

  .window__wrapper {
    filter: grayscale(1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    mix-blend-mode: luminosity;
    opacity: 0.6;
  }
}
