/*============================================================================*\
  Hero slider
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .hero-slider__arrow {
    &:hover {
      svg {
        transform: translateX(0) !important;
      }
    }
  }

  .video-is-playing {
    .hero-slider__arrow {
      display: none;
    }
  }
}
