/* stylelint-disable */
/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  @include box-shadow;
  background: white;
  padding: 1rem;
}

.table-labeled {
  th {
    @apply py-4;
    background-color: theme('colors.grey.default');
    border: 1px solid theme('colors.grey.medium');
    vertical-align: middle;
  }
  td {
    vertical-align: middle;
    border: 1px solid theme('colors.grey.medium');
  }
  tbody tr td {
    @apply py-2 px-2;
    border: 1px solid theme('colors.grey.medium');
  }

  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    color: white;
    white-space: nowrap;
  }
}
.page-order {
  .table {
    margin-bottom: 0;
  }
  table {
    th {
      padding: 0.5rem;
    }
    td {
      padding: 0.5rem;
    }
    thead {
      th {
        text-align: center;
      }
    }
  }
}
// #authentication {
//   .tooltip.tooltip-bottom {
//     padding: 0;
//     margin: 0;
//   }
//   .custom-checkbox {
//     display: flex;
//     span {
//       flex: 0 0 0.9375rem;
//     }
//     label {
//       padding-left: 0.625rem;
//     }
//   }
// }
#identity{
  .radio-inline {
    padding: 0;
    .custom-radio {
      margin-right: 0;
    }
  }
}

/*** Addresses page ***/
.page-addresses {
  .address {
    @include box-shadow;
    background: white;
    margin-bottom: 1.875rem;
    font-size: $font-size-sm;
    color: $gray-darker;
    .address-body {
      padding: 1rem;
      h4 {
        font-size: $base-font-size;
        font-weight: 700;
      }
      address {
        min-height: 9rem;
      }
    }
    .address-footer {
      border-top: 1px solid $gray;
      padding: 0.5rem 1rem;
      a {
        color: $gray;
        margin-right: 0.5rem;
        &:hover {
          color: $gray-darker;
        }
        i {
          font-size: $base-font-size;
        }
        span {
          font-size: $font-size-lg;
          vertical-align: middle;
        }
      }
    }
  }
  .addresses-footer {
    margin: 0 0.9375rem;
    a {
      color: $gray-darker;
      &:hover {
        color: $gray-darker;
      }
      i {
        font-size: $base-font-size;
      }
      span {
        font-size: $base-font-size;
        vertical-align: middle;
        margin-top: $small-space;
      }
    }
  }
}
/*** Order details page ***/
.page-order-detail {

  #order-products {
    &.return {
      margin-bottom: 1rem;
      th {
        &.head-checkbox {
          width: 30px;
        }
      }
      td {
        padding: 1.375rem 0.75rem;
        &.qty {
          min-width: 125px;
          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }
          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;
            select {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .customization {
    margin-top: 0.75rem;
  }
}
/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }
  .customization {
    margin-top: 0.75rem;
  }
}
/*** My account landing page ***/
.page-my-account {
  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      a {
        text-align: center;
        display: inline-block;
        font-size: $base-font-size;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray;
        padding: 0 0.9375rem;
        margin-bottom: 1.875rem;
        span.link-item {
          display: block;
          height: 100%;
          @include customer-area-base-box;
        }
        i {
          display: block;
          font-size: 2.6rem;
          width: 100%;
          color: $gray-darker;
          padding-bottom: 3.4rem;
        }
        &:hover {
          color: $gray-darker;
          i {
            color: $brand-primary;
          }
        }
      }
    }
  }
}
/*** History page ***/
#history {
  .orders {
    margin: 0 -1rem;
    .order {
      a {
        h3 {
          color: $gray;
        }
      }
      padding: 0.75rem 1rem;
      border-bottom: 1px solid theme('colors.grey.medium');
      .label {
        display: inline-block;
        margin: 0.25rem 0;
        padding: 0.25rem 0.375rem;
        color: white;
        border-radius: 3px;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/*** FOOTER ***/
.page-footer {
  .account-link {
    margin-right: 1rem;
    i {
      font-size: $base-font-size;
    }
    span {
      font-size: $font-size-sm;
      vertical-align: middle;
    }
  }
}

.login-form {
  margin-top: 15px;
}
