/*============================================================================*\
  Button
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin btn {
  @apply text-body leading-body font-degular font-semibold text-white bg-black border border-transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.938em 1.489em;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s, box-shadow 0.5s;

  * {
    pointer-events: none;
  }

  svg {
    &:first-child:not(:last-child) {
      margin-right: 1em;
    }

    &:last-child:not(:first-child) {
      margin-left: 1em;
    }
  }

  &:hover,
  &:focus {
    @apply bg-grey-greyish;
    outline: none;
  }

  &.btn--disabled,
  &:disabled {
    pointer-events: none;
    box-shadow: none;
    opacity: 0.3;
  }
}

@if $add-class {
  .btn {
    @include btn;
  }
}

/* Stroke
\*==========================================================================*/

@mixin btn-stroke {
  @apply border-current;
  background-color: transparent;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

@if $add-class {
  .btn--stroke {
    @include btn-stroke;
  }
}

/* No appearance
\*==========================================================================*/

@mixin btn-no-appearance {
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 0;

  &:hover,
  &:focus {
    outline: none;
  }
}

@if $add-class {
  .btn--no-appearance {
    @include btn-no-appearance;
  }
}
