/*============================================================================*\
  Alert
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin alert {
  @apply py-3 px-6 type-body-fluid text-white font-degular font-medium bg-black;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

@if $add-class {
  .alert {
    @include alert;
  }
}

/* Error
\*==========================================================================*/

@mixin alert-error {
  @apply bg-notice-red;
}

@if $add-class {
  .alert--error {
    @include alert-error;
  }
}

/* Success
\*==========================================================================*/

@mixin alert-success {
  @apply bg-notice-green;
}

@if $add-class {
  .alert--success {
    @include alert-success;
  }
}

/* Warning
\*==========================================================================*/

@mixin alert-warning {
  @apply bg-notice-orange;
}

@if $add-class {
  .alert--warning {
    @include alert-warning;
  }
}
