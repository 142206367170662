/*==========================================================================*\
  Header desktop
\*==========================================================================*/

/* Base
\*==========================================================================*/

.header-desktop {
  transition: color 0.3s, transform 0.3s;

  &:before {
    @apply bg-grey-light;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
    transform: translateY(-100%);
    transition: transform 0.3s;
  }

  .is-scroll & {
    &:before {
      transform: translateY(0);
    }
  }

  .is-scroll.is-scroll-bottom & {
    transform: translateY(-100%);
  }
}

.header-desktop__logo-link {
  .icon {
    transition: height 0.3s; /* stylelint-disable-line plugin/no-low-performance-animation-properties */
  }

  .is-scroll & {
    .icon {
      height: 3.75rem;
    }
  }
}

.search__block {
  opacity: 0;
  transform: translateY(-100%);

  .is-search-open & {
    opacity: 1;
    transform: translateY(0);
  }

  @screen l {
    height: 0;
    opacity: 1;
    transform: none;

    .is-search-open & {
      opacity: 1;
      transform: none;
    }
  }
}

.menu-cart-link {
  transition: color 0.3s;

  .cart-products-count {
    transition: background-color 0.3s;
  }

  &:hover,
  &:focus {
    @apply text-orange;

    .cart-products-count {
      @apply bg-orange;
    }
  }
}

/* Links white
\*==========================================================================*/

.header-desktop--links-white {
  @apply text-white;

  .is-scroll & {
    color: currentColor;
  }
}

.menu-desktop__list--tertiary {
  .menu-desktop__submenu-close {
    @apply opacity-0;
  }

  &.is-open {
    @apply pointer-events-auto;

    .menu-desktop__submenu-close {
      @apply opacity-100;
    }
  }
}
