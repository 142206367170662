/*============================================================================*\
  Field select
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-select {
  @apply type-body font-degular font-medium;
  display: block;
  width: 100%;
  padding: 1.219em 0;
  padding-right: 3.6875em;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  // https://yoksel.github.io/url-encoder/
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10px' height='6.5px' viewBox='0 0 10 6.5'%3E%3Cpolygon fill='%23e95b2b' points='5,6.5 0,1.4 1.4,0 5,3.6 8.6,0 10,1.4'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 1.375em center;
  border: 1px solid transparent;
  border-bottom: 1px solid #{theme('colors.grey.medium')};
  outline: none;

  &:focus {
    border-bottom-color: #{theme('colors.brown.default')};
  }

  &::placeholder {
    color: rgba(#333, 0.5);
  }

  // Disabled
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    // https://yoksel.github.io/url-encoder/
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10px' height='6.5px' viewBox='0 0 10 6.5'%3E%3Cpolygon fill='%23d0021b' points='5,6.5 0,1.4 1.4,0 5,3.6 8.6,0 10,1.4'/%3E%3C/svg%3E%0A");
    border-bottom-color: #{theme('colors.notice-red')};
    outline: none;
  }
}

@if $add-class {
  .field-select {
    @include field-select;
  }
}

/* Selectric
\*==========================================================================*/

@if $add-class {
  .selectric-field-select,
  .selectric-product-combination {
    width: 100%;

    .selectric {
      @include field-select;
      background-image: none !important;

      .label {
        display: block;
        height: auto;
        margin: 0;
        overflow: hidden;
        font-size: inherit;
        line-height: inherit;
        color: currentColor;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
      }

      .button {
        @apply text-orange-dark;
        top: 0;
        right: 1.375em;
        bottom: 0;
        width: 10px;
        height: 7px;
        margin-top: auto;
        margin-bottom: auto;
        font: inherit;
        background-color: transparent;
        transition: transform 0.3s;

        /* stylelint-disable selector-max-compound-selectors */
        .field-group.has-error & {
          @apply text-notice-red;
        }
        /* stylelint-enable */
      }
    }

    .selectric-items {
      @apply border border-grey-medium type-body font-degular font-medium text-grey-slate bg-grey-light;
      box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);

      ul {
        min-height: auto;
        font: inherit;
      }

      li {
        min-height: auto;
        padding: 0.75em 1.75em;
        font: inherit;

        &.selected {
          @apply text-orange-dark bg-grey-lighter;
        }

        &:hover:not(.selected) {
          @apply bg-grey-lighter text-grey-greyish;
        }
      }
    }

    /* stylelint-disable no-descending-specificity */
    &.selectric-open {
      .selectric {
        .button {
          transform: rotate(180deg);
        }
      }
    }
    /* stylelint-enable */
  }
}
