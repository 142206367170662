/*============================================================================*\
  Cursor
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin cursor {
  @apply type-body text-white font-medium bg-black;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  pointer-events: none;
  cursor: none;
  border-radius: 50%;

  @screen l {
    display: flex;
  }
}

@if $add-class {
  .cursor {
    @include cursor;
  }
}
