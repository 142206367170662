/*============================================================================*\
  Icon
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin icon {
  display: inline-block;
  line-height: 0;
}

@if $add-class {
  .icon {
    @include icon;
  }
}

/* Container
\*==========================================================================*/

@mixin icon-container-fluid {
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

@if $add-class {
  .icon-container-fluid {
    @include icon-container-fluid;
  }
}
