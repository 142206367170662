/*==========================================================================*\
  Menu mobile
\*==========================================================================*/

/* Base
\*==========================================================================*/

.menu-mobile__submenu {
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.menu-mobile__item {
  &.is-open {
    > .menu-mobile__submenu {
      transform: translateX(0);
    }
  }
}
