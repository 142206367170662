/*============================================================================*\
  Block collaborations
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .block-collaborations__image {
    width: 281px;
    height: 320px;
  }
}
