/*============================================================================*\
  Field status
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-status {
  @apply type-body font-degular font-medium;
  display: block;
  width: 100%;

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
  }
}

@if $add-class {
  .field-status {
    @include field-status;
  }
}
