/* stylelint-disable */
.customization-modal {
  .modal-content {
    border-radius: 0;
    border: 1px solid $gray-lighter;
    .modal-body {
      padding-top: 0;
      .product-customization-line {
        .label {
          font-weight: bold;
          text-align: right;
        }
        padding-bottom: 0.9375rem;
        padding-top: 0.9375rem;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          padding-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
}
