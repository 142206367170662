/*============================================================================*\
  Block categories
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .block-categories__link-arrow {
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
    transition: opacity 0.3s, transform 0.3s;
  }

  .block-categories__link {
    &.is-show {
      @apply text-orange-dark;

      .block-categories__link-arrow {
        opacity: 1;
        transform: translateX(0) translateY(-50%);
        transition: opacity 0.3s, transform 0.3s;
      }
    }
  }
}
