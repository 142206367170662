/*============================================================================*\
  Price style
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin price-style {
  @apply text-body leading-body font-degular font-medium;
}

@if $add-class {
  .price-style {
    @include price-style;
  }
}

/* Crossed
\*==========================================================================*/

@mixin price-style-crossed {
  text-decoration: line-through;
}

@if $add-class {
  .price-style--crossed {
    @include price-style-crossed;
  }
}

/* Promo
\*==========================================================================*/

@mixin price-style-promo {
  @apply text-orange-dark;
}

@if $add-class {
  .price-style--promo {
    @include price-style-promo;
  }
}
