/*============================================================================*\
  Group accessories
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  /* stylelint-disable */

  $cell-spacing: 1rem;
  $cell-padding-y: 0rem;
  $cell-padding-x: 0rem;

  .accessory_dropdown_wrap {
    width: 100% !important;
  }

  .ma_block_qty {
    vertical-align: middle;
  }

  #product #group_accessories {
    color: theme('colors.grey.slate') !important;
    background-color: transparent !important;
  }

  #group_accessories .message_error {
    display: block;
    padding: 0.5rem;
    font-size: theme('fontSize.small') !important;
    color: theme('colors.notice-red') !important;
    background: theme('colors.grey.default');
    line-height: theme('lineHeight.small');
  }

  #product #group_accessories .custom_quantity {
    border: none !important;
    height: auto !important;
    width: 26px !important;
  }

  #product #group_accessories .option-row table {
    margin: 0 !important;
  }

  #group_accessories .option-row table tr + tr {
    border-top: 1px solid theme('colors.grey.medium');
  }

  #group_accessories .option-row table td {
    padding: $cell-padding-y !important;
    padding-left: $cell-spacing / 2 !important;
    padding-right: $cell-spacing / 2 !important;
  }

  #group_accessories .option-row table td:last-of-type {
    padding-right: $cell-padding-x !important;
  }

  #group_accessories .option-row table td:first-of-type {
    padding-left: $cell-padding-x !important;
  }

  #group_accessories .select_box {
    width: auto;
  }

  #product #group_accessories .accessories_group_combination select.product-combination {
    @include field-select;
  }

  #product #group_accessories .accessories_group_combination .selectric {
    padding: 0.5em 3.6875em 0.5em 0.5em !important;
  }

  #product #group_accessories .display_combination {
    margin: 0 !important;
  }
  /* stylelint-enable */
}
