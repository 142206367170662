/*============================================================================*\
  Field textarea
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-textarea {
  @apply type-body font-degular font-medium;
  display: block;
  width: 100%;
  padding: 1.375em 2em;
  background-color: transparent;
  border: 1px solid #{theme('colors.grey.medium')};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #{theme('colors.grey.slate')};
  }

  &::placeholder {
    color: currentColor;
    opacity: 0.6;
  }

  // Disabled
  &:disabled {
    cursor: default;
    opacity: 0.6;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    border-color: #{theme('colors.notice-red')};
    outline: none;
  }
}

@if $add-class {
  .field-textarea {
    @include field-textarea;
  }
}
