/*============================================================================*\
  Field number
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

// Group
@mixin field-number {
  display: inline-flex;
  align-items: stretch;

  .input-group-btn {
    display: inline-flex;
    flex: 1;
    align-items: center;
  }
}

@if $add-class {
  .field-number {
    @include field-number;
  }
}

// Button
@mixin field-number-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0.75em;
  outline: none;
  transition: background-color 0.2s;

  &:hover,
  &:focus {
    @apply bg-grey-medium;
    outline: none;
  }

  * {
    pointer-events: none;
  }
}

@if $add-class {
  .field-number__btn {
    @include field-number-btn;
  }
}

// Input
@mixin field-number-input {
  @apply font-medium;
  width: 26px;
  min-width: 0;
  max-width: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  background-color: transparent;
  outline: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply hidden;
    margin: 0;
  }

  &:hover,
  &:focus {
    @apply bg-grey-medium;
  }
}

@if $add-class {
  .field-number__input {
    @include field-number-input;
  }
}

/* Touchspin override
\*==========================================================================*/

.bootstrap-touchspin {
  display: inline-flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
}
