/*============================================================================*\
  Hero
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .hero {
    height: 100vh;

    @screen s {
      height: 95vh;
    }
  }

  .hero__img {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;
    @apply ease-out-cubic;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);
      @apply ease-out-cubic;
    }
  }

  .hero__video {
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
    transform-origin: 50% 50%;
    will-change: opacity, transform;
    transition: opacity 1.4s, transform 1.4s;
    object-fit: cover;
    object-position: center center;

    .is-lazy-loaded & {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .hero__overlay {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 30%, #000000),
      linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    opacity: 0.4;
  }

  @screen m {
    .hero__overlay {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 25%, #000000),
        linear-gradient(to left, rgba(0, 0, 0, 0), #000000);
    }
  }
}
