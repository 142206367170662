/*============================================================================*\
  Link
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin link {
  @apply text-body leading-body font-degular font-medium;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  text-align: center;
  text-decoration: none;
  user-select: none;
  outline: none;
  transition: color 0.3s;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -4px;
    left: 0;
    height: 1px;
    background-color: currentColor;
    opacity: 0.25;
    transform: scaleY(1);
    transform-origin: 50% 100%;
    transition: opacity 0.3s, transform 0.3s;
  }

  &:not(.link--icon, .link--animation, .link--underline-red-disabled):after {
    @apply bg-orange;
    content: '';
    position: absolute;
    bottom: -4px;
    width: 100%;
    height: 2px;
    transform: scale(0, 1);
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }

  &:hover,
  &:focus {
    @apply text-black;
    text-decoration: none;

    &:not(.link--icon, .link--animation, .link--underline-red-disabled):after {
      transform: scale(1);
      transform-origin: bottom left;
    }
  }

  svg {
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

@if $add-class {
  .link {
    @include link;
  }
}

/* Underline red
\*==========================================================================*/

@mixin link-underline-red {
  @apply text-body leading-body font-degular font-medium;

  &:before {
    @apply bg-orange-dark;
    bottom: -15px;
    height: 2px;
    opacity: 1;
  }

  &:after {
    bottom: -15px !important;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }
}

@if $add-class {
  .link--underline-red {
    @include link-underline-red;
  }
}

@mixin link-underline-red-disabled {
  opacity: 0.6;
  transition: color 0.3s, opacity 0.3s;

  &:before {
    opacity: 0;
    transform: scaleY(0);
  }

  &:hover,
  &:focus {
    opacity: 0.6;

    &:before {
      opacity: 0;
      transform: scaleY(1);
    }
  }
}

@if $add-class {
  .link--underline-red-disabled {
    @include link-underline-red-disabled;
  }

  // Bootstrap Component
  .link--underline-red {
    &.nav-link:not(.active) {
      @include link-underline-red-disabled;
    }
  }
}

/* Animation
\*==========================================================================*/

@mixin link-animation {
  &:hover,
  &:focus {
    text-decoration: none;
  }

  /* stylelint-disable */
  &:before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    background-color: transparent;
    transition: width 0s ease, background-color 0.3s ease;
    opacity: 1;
  }

  &:after {
    @apply bg-orange;
    content: '';
    position: absolute;
    right: 0;
    bottom: -15px;
    display: block;
    width: 0;
    height: 2px;
    transition: width 0.3s ease;
  }

  &:hover,
  &:focus,
  &.is-active {
    &:before {
      @apply bg-orange;
      width: 100%;
      transition: width 0.3s ease;
      transform: scaleY(1);
      opacity: 1;
    }

    &:after {
      width: 100%;
      background-color: transparent;
      transition: all 0s ease;
    }
  }
  /* stylelint-enable */
}

@mixin link-animation-active {
  /* stylelint-disable */
  &:before {
    @apply bg-orange-dark;
    width: 100%;
    transition: width 0.3s ease;
  }

  &:after {
    width: 100%;
    background-color: transparent;
    transition: all 0s ease;
  }
  /* stylelint-enable */
}

@if $add-class {
  .link--animation {
    @include link-animation;

    &:hover,
    &:focus,
    &.is-active {
      @include link-animation-active;
    }
  }
}

/* Icon
\*==========================================================================*/

@mixin link-icon {
  @apply text-body leading-body font-degular font-medium;

  &:before {
    display: none;
  }

  .material-icons,
  .icon,
  svg {
    @apply text-orange-dark;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }
}

@if $add-class {
  .link--icon {
    @include link-icon;
  }
}
