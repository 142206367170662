/*============================================================================*\
  Field checkbox
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@if $add-class {
  .bootstrap-filestyle {
    @apply type-body font-degular font-medium;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid #{theme('colors.grey.medium')};
    outline: none;
    transition: border-color 0.3s;

    &:focus {
      border-bottom: 1px solid #{theme('colors.grey.slate')};
    }

    &::placeholder {
      color: currentColor;
      opacity: 0.6;
    }

    // Form group status
    .field-group.has-error & {
      @apply text-notice-red;
      border-bottom-color: #{theme('colors.notice-red')};
      outline: none;
    }

    input[type='text'] {
      flex-grow: 2;
      background: transparent;
    }
  }
}
