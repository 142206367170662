/*============================================================================*\
  Headline
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  // Base
  .headline {
    min-height: 85vh;
    overflow: hidden;

    @screen l {
      min-height: 100vh;
    }

    @media (min-width: 1280px) and (min-height: 920px) {
      min-height: 920px;
    }
  }

  .headline__window {
    &:before {
      content: '';
      display: block;
      padding-bottom: 150%;
    }

    @screen s {
      &:before {
        display: none;
      }
    }
  }

  .headline__title {
    font-size: 30px;

    @screen xxs {
      font-size: fluidTypographie(30, 80);
    }

    @screen xl {
      font-size: 80px;
    }
  }

  // CMS
  .headline--cms {
    min-height: 25.5rem;

    @screen s {
      min-height: 85vh;
    }

    @media (min-width: 1280px) and (min-height: 920px) {
      min-height: 920px;
    }
  }
}
