/*============================================================================*\
  Badge
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin badge {
  @apply text-small text-white leading-small font-degular font-semibold bg-orange;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.215em 0.707em;
  text-align: center;
  user-select: none;
}

@if $add-class {
  .badge {
    @include badge;
  }
}
