/*============================================================================*\
  Modal
\*============================================================================*/

$add-class: true !default;

/* Classes
\*==========================================================================*/

@if $add-class {
  .modal-dialog {
    @apply bg-grey-light border border-grey-medium;
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.1);
  }

  .modal-dialog--large {
    max-width: 1547px;
  }

  .modal-backdrop {
    @apply bg-grey-light;
  }
}
