/* stylelint-disable */
.sitemap-title {
  text-transform: capitalize;
}

.sitemap {
  margin-top: 0.9375rem;

  h2 {
    color: $gray-darker;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    border-bottom: 1px solid $gray;
    margin-left: -15px;
    width: 100%;
    height: 35px;
  }

  ul {
    margin-left: -15px;
    margin-top: 20px;

    &.nested {
      margin-left: 20px;
    }

    li {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .sitemap {
    margin-top: 0;
  }
}
