/*============================================================================*\
  Field checkbox
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

// Wrapper
@mixin field-checkbox {
  display: inline-flex;
  align-items: baseline;
  cursor: pointer;
  outline: none;
}

@if $add-class {
  .field-checkbox {
    @include field-checkbox;
  }
}

// Input
@mixin field-checkbox-input {
  position: absolute;
  left: -99999px;

  &:focus {
    ~ .field-checkbox__fake-input {
      &:after {
        box-shadow: 0 0 0 2px #{theme('colors.orange.dark')};
      }
    }
  }

  &:checked {
    ~ .field-checkbox__fake-input {
      &:before {
        opacity: 1;
      }
    }
  }
}

@if $add-class {
  .field-checkbox__input {
    @include field-checkbox-input;
  }
}

// Fake Input
@mixin field-checkbox-fake-input {
  top: 0.1em;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 1em;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    margin: auto;
    background-color: transparent;
    // https://yoksel.github.io/url-encoder/
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13px' height='10px' viewBox='0 0 13 10'%3E%3Cpath fill='%23e95b2b' d='M5.4,10L0.3,4.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.6,3.6l6-6.8c0.4-0.4,1-0.4,1.4-0.1 c0.4,0.4,0.5,1,0.1,1.4L5.4,10z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.1s;
  }

  &::after {
    @apply border border-grey-medium;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

@if $add-class {
  .field-checkbox__fake-input {
    @include field-checkbox-fake-input;
  }
}

// Label
@mixin field-checkbox-label {
  @apply font-light;
  display: flex;
  flex: 1;
  justify-content: start;
  text-align: left;
  cursor: pointer;

  a {
    @include link;
  }
}

@if $add-class {
  .field-checkbox__label {
    @include field-checkbox-label;
  }
}
