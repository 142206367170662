/*==========================================================================*\
  Header mobile
\*==========================================================================*/

/* Base
\*==========================================================================*/

.header-mobile {
  transition: color 0.3s;

  &:before {
    @apply bg-grey-light;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.05);
    transform: translateY(-100%);
    transition: transform 0.3s;
  }

  .is-scroll &,
  .is-menu-open & {
    &:before {
      transform: translateY(0);
    }
  }
}

.header-mobile__menu-btn-icon--open {
  transition: opacity 0.3s;

  .is-menu-open & {
    opacity: 0;
  }
}

.header-mobile__menu-btn-icon--close {
  opacity: 0;
  transition: opacity 0.3s;

  .is-menu-open & {
    opacity: 1;
  }
}

.header-mobile__logo {
  transition: opacity 0.3s;

  .is-menu-open & {
    pointer-events: none;
    opacity: 0;
  }
}

.header-mobile__action-secondary {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;

  .is-menu-open & {
    pointer-events: auto;
    opacity: 1;
  }
}

.header-mobile__aside {
  transform: translateX(-100%);
  transition: transform 0.3s;

  .is-menu-open & {
    transform: translateX(0);
  }
}

/* Links white
\*==========================================================================*/

.header-mobile--links-white {
  @apply text-white;

  .is-menu-open &,
  .is-scroll & {
    color: currentColor;
  }
}
