/*==========================================================================*\
  Menu desktop
\*==========================================================================*/

$add-class: false;
@import '../elements/link';
$add-class: true;

/* Base
\*==========================================================================*/

.menu-desktop__overlay {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s 0s, transform 0s 0.5s;

  .is-submenu-open & {
    opacity: 0.3;
    transform: translateX(0);
    transition: opacity 0.5s 0s, transform 0s 0s;
  }
}

.menu-desktop__link--tertiary {
  .icon--arrow-back {
    margin-top: 2px;
  }
}

.menu-desktop__link {
  .icon--arrow-back {
    opacity: 0;
    transform: translateX(50%) translateY(-50%);
    transition: opacity 0.3s, transform 0.3s;
  }

  &:hover,
  &:focus {
    .icon--arrow-back {
      opacity: 1;
      transform: translateX(0) translateY(-50%);
      transition: opacity 0.3s, transform 0.3s;
    }
  }
}

.menu-desktop__link--primary {
  @include link-animation;

  &:hover,
  &:focus,
  &.is-active {
    @include link-animation-active;
  }

  .is-submenu-open & {
    @apply text-grey-slate;
  }
}

.menu-desktop__submenu {
  width: calc(50% - 200px);
  transform: translateX(-100%);
  transition: transform 0.5s;

  &.is-open {
    transform: translateX(0);
  }
}

.menu-desktop__list--secondary {
  &:before {
    @apply border-r border-grey bg-grey-light;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 200px);
    height: 100%;
  }
}

.menu-desktop__item--secondary {
  width: calc(50% - 200px - 2em);
}

.menu-desktop__link--secondary {
  transition: color 0.3s;
}

.menu-desktop__list--tertiary {
  left: calc(50% - 200px);
  transform: translateX(-100%);
  transition: transform 0.3s;

  &.is-open {
    transform: translateX(0);
  }
}
