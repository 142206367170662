/*============================================================================*\
  Wysiwyg
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin wysiwyg {
  // Title
  h1 {
    @apply type-h1;
  }

  h2 {
    @apply type-h2;
  }

  h3 {
    @apply type-h3;
  }

  h4 {
    @apply type-h4;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  // Inline tag
  strong,
  b {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a:not(.btn) {
    @apply text-grey-slate;
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
      @apply text-orange-dark;
    }
  }

  // Paragraph
  p {
    font: inherit !important;
    background: inherit !important;
    @apply font-helvetica #{!important};
  }

  // Image and figure
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  figure {
    margin-bottom: 2em;
  }

  figcaption {
    @apply text-small;
  }

  // Blockquote
  blockquote {
    @apply type-h3 font-degular font-regular border-l border-grey-medium;
    padding: 0.125em 2em;
  }

  // List
  ul {
    @apply font-helvetica;
    list-style-type: none;

    li {
      padding-left: 2em;

      &:before {
        @apply bg-orange-dark;
        content: '';
        position: absolute;
        top: 0.75em;
        left: 0;
        width: 1em;
        height: 1px;
      }
    }
  }

  ol {
    @apply font-helvetica #{!important};

    li {
      list-style-type: decimal;
    }
  }

  // Datalist
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: 500;
    }

    dd,
    dt {
      flex: 0 0 45%;
      margin: 0.125rem;
      padding: 0.625rem;
      @apply bg-grey-medium;

      &:nth-of-type(even) {
        @apply bg-grey-light;
      }
    }
  }

  // All tag vertical marge
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.5em;
    }
  }

  /* stylelint-disable no-descending-specificity */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  img,
  figure,
  blockquote,
  ul,
  ol,
  dl,
  pre {
    span {
      font: inherit !important;
      background: inherit !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
  }
  /* stylelint-enable */
}

@if $add-class {
  .wysiwyg {
    @include wysiwyg;
  }
}

/* CMS content
\*==========================================================================*/

@mixin wysiwyg-cms-content {
  @apply type-body-fluid;

  // Title
  h1,
  h2 {
    &:first-child:not(:last-child) {
      margin-bottom: 3.5rem;
    }

    &:last-child:not(:first-child) {
      margin-top: 3.3rem;
    }
  }

  h3 {
    &:first-child:not(:last-child) {
      margin-bottom: 2.3rem;
    }

    &:last-child:not(:first-child) {
      margin-top: 3.3rem;
    }
  }

  h4 {
    &:first-child:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:last-child:not(:first-child) {
      margin-top: 3.3rem;
    }
  }

  // Paragraph
  p {
    &:not(:last-child) {
      margin-bottom: 2.6rem;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    + p {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }

  .intro {
    @apply type-h2 #{!important};

    @screen m {
      max-width: 57.9375rem;
      margin-left: 0;
    }
  }

  // Image and figure
  img {
    &:first-child:not(:last-child) {
      margin-bottom: 2.5rem;
    }

    &:last-child:not(:first-child) {
      margin-top: 2.5rem;
    }

    @screen s {
      &:first-child:not(:last-child) {
        margin-bottom: 5rem;
      }

      &:last-child:not(:first-child) {
        margin-top: 5rem;
      }
    }
  }

  // Blockquote
  blockquote {
    @apply type-h2;
    padding: 0 2.5rem;
    border-left: 1px solid rgba(#333333, 0.2);

    &:first-child:not(:last-child) {
      margin-bottom: 4rem !important;
    }

    &:last-child:not(:first-child) {
      margin-top: 4rem;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    p {
      font: inherit !important;
    }

    @screen s {
      padding: 0 3.5rem;
    }
  }

  // List
  ul {
    &:first-child:not(:last-child) {
      margin-bottom: 3.3rem;
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1.5rem;
    line-height: 2rem;

    &:before {
      @apply bg-orange-dark;
      content: '';
      position: relative;
      left: -1.5rem;
      width: 1rem;
      height: 1px;
    }
  }
}

@if $add-class {
  .wysiwyg--cms-content {
    @include wysiwyg-cms-content;
  }
}

/* CMS content
\*==========================================================================*/

@mixin wysiwyg-tag-outer {
  .intro,
  img {
    @screen m {
      left: 50%;
      width: calc(100vw - #{theme('spacing.mobile-container-margin')});
      max-width: #{$main-width}px;
      transform: translateX(-50%);
    }
  }
}

@if $add-class {
  .wysiwyg--tag-outer {
    @include wysiwyg-tag-outer;
  }
}
