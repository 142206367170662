/* stylelint-disable */
/*!
 * Bootstrap v4.0.0-alpha.5 (https://getbootstrap.com)
 * Copyright 2011-2016 The Bootstrap Authors
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// extends from: @import "~bootstrap/scss/bootstrap";

// Core variables and mixins
@import "~bootstrap/scss/custom";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// Core CSS
@import "~bootstrap/scss/tables";

// Components
@import "~bootstrap/scss/animation";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/nav";

// Components w/ JavaScript
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
