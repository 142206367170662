/*============================================================================*\
  Field label
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-label {
  @apply text-body leading-body font-degular font-regular;
}

@if $add-class {
  .field-label {
    @include field-label;
  }
}
