/*==========================================================================*\
  Footer
\*==========================================================================*/

/* Réassurance
\*==========================================================================*/

.block-reassurance-item-icon {
  width: 20%;

  @screen xs {
    width: 10%;
  }

  @screen s {
    width: inherit;
  }
}

.block-reassurance-item-text {
  @screen xs {
    width: 40%;
  }

  @screen s {
    width: inherit;
  }
}
