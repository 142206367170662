/*============================================================================*\
  Field text
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin field-text {
  @apply type-body font-degular font-medium;
  display: block;
  width: 100%;
  padding: 1.219em 0;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom: 1px solid #{theme('colors.grey.medium')};
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-bottom: 1px solid #{theme('colors.grey.slate')};
  }

  &::placeholder {
    color: currentColor;
    opacity: 0.6;
  }

  // Form group status
  .field-group.has-error & {
    @apply text-notice-red;
    border-bottom-color: #{theme('colors.notice-red')};
    outline: none;
  }
}

@if $add-class {
  .field-text {
    @include field-text;
  }
}

/* Password show
\*==========================================================================*/

@if $add-class {
  .field-text__toggle {
    width: 3.5rem;
    font-size: 0;

    .icon--eye-hidden {
      opacity: 0;
    }

    .field-group.has-error & {
      @apply text-notice-red;
    }
  }

  .field-text--password[type='text'] {
    padding-right: 3.5rem;

    ~ .field-text__toggle {
      .icon--eye {
        opacity: 0;
      }

      .icon--eye-hidden {
        opacity: 1;
      }
    }
  }
}
