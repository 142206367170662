/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/

*,
*:before,
*:after {
  position: relative;
}

html {
  -webkit-font-smoothing: antialiased;

  &.is-menu-open,
  &.is-submenu-open,
  &.is-search-open,
  &.is-filters-open {
    overflow: hidden;
  }

  @screen l {
    &.is-menu-open,
    &.is-search-open,
    &.is-filters-open {
      overflow: auto;
    }
  }
}

body {
  @apply type-body text-grey-slate bg-grey-light;
}

/* Container
\*==========================================================================*/

// Page container
.page-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}

// Main container
.main-center {
  width: 100%;
  width: calc(100% - #{theme('spacing.mobile-container-margin')});
  max-width: 1216px;
  margin-right: auto;
  margin-left: auto;

  > .grid {
    padding-right: 0;
    padding-left: 0;
  }
}
