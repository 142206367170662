/*============================================================================*\
  Hr
\*============================================================================*/

$add-class: true !default;

/* Basic
\*==========================================================================*/

@mixin hr {
  border: 0;
  border-bottom: 1px solid currentColor;
}

@if $add-class {
  .hr {
    @include hr;
  }
}
