/* stylelint-disable */
$carrierComponent: 'carrier';

body#checkout {
  section.checkout-step {
    .step-title {
      cursor: pointer;
    }
    .not-allowed {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .content,
    .done,
    .step-edit {
      display: none;
    }
    &.-unreachable {
      .step-title {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    &.-current {
      .step-title {
        cursor: default;
      }
      .content {
        display: block;
      }
    }
    &.-current.-reachable.-complete {
      .done,
      .step-edit {
        display: none;
      }
      .step-number {
        display: inline-block;
      }
      .content {
        display: block;
      }
    }
    &.-reachable.-complete {
      h1 {
        .done {
          display: inline-block;
        }
        .step-number {
          display: none;
        }
        .step-edit {
          cursor: pointer;
          display: block;
        }
      }
      .content {
        display: none;
      }
    }

    .step-edit {
      display: inline;
    }
    .step-edit:hover {
      cursor: pointer;
    }
  }
}

/**** ORDER CONFIRMATION *****/
#order-items {
  .h3 {
    &._desktop-title {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.js-payment-binary {
  display: none;
  .accept-cgv {
    display: none;
  }
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
    .accept-cgv {
      display: block;
    }
  }
}
